.wrapper {
    display: flex;
    width: 100%;
    background-color: #F5F5F5;
}

.wrapper .box_left {
    padding: 24px;
    width: 100%;
    height: calc(100vh - 96px);
    overflow: auto;
}

.wrapper .box_left .top_text .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #292d32;
}

.wrapper .box_left .top_text .subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #a6b1bb;
    margin-top: 6px;
}

.wrapper .box_left .cards {
    display: flex;
    column-gap: 24px;
    margin-top: 24px;
}

.wrapper .box_left .card {
    width: 100%;

    background: #ffffff;
    border-radius: 10px;
    padding: 16px;
}

.wrapper .box_left .cards .card_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box_left .cards .card_head .select {
    width: 106px;
    height: 24px;
    border: 1px solid #efefef;
    border-radius: 6px;

    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #292d32;
}

.box_left .cards .chart {
    width: 100%;
    height: 241px !important;
    margin-top: 24px;
}

.wrapper .box_left .card .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #2b2f34;
}

.box_left .card .card_items {
    display: flex;
    column-gap: 19px;
    align-items: center;
    margin: 24px 0 47px;
}

.box_left .card .card_items .item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 4px;
    width: 100%;
    height: 149px;
    border: 1px solid #efefef;
    border-radius: 10px;
    text-align: center;
}

.box_left .card .card_items .item .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #a6b1bb;
    margin-top: 8px;
}

.box_left .card .card_items .item .count {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #2b2f34;
}

.box_left .card .card_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box_left .card .card_footer .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #8c9194;
}

.box_left .card .card_footer .count {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #2b2f34;

    display: flex;
    align-items: center;
    column-gap: 12px;
}

.box_left .card .card_footer .count span {
    padding: 4px 6px;
    background: #03bdb3;
    border-radius: 6px;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
}

.box_left .box_left__footer {
    margin-top: 24px;
    width: 100%;
}

.box_left .box_left__footer .footer_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.box_left .box_left__footer .footer_head .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2b2f34;
}

.box_left .box_left__footer .footer_head .more_btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #03bdb3;
}

.box_left .box_left__footer .card {
    margin-top: 24px;
    height: 117px;
    display: flex;
    justify-content: space-between;
}

.box_left .box_left__footer .card .card_left {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.box_left__footer .card .card_left .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #a6b1bb;
}

.box-left__footer .card .card_left .text {
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    color: #2b2f34;
    margin-top: 2px;
}

.box_left__footer .card .card_chips {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.box_left__footer .card .card_chips .chip {
    display: flex;
    align-items: center;
    column-gap: 6px;

    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    color: #a6b1bb;

    padding: 6px 8px;
    background: #f6f7fa;
    border-radius: 8px;
}

.box_left__footer .card .card_right {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
}

.box_left__footer .card .card_right .members {
    display: flex;
}

.card .card_right .members .img {
    width: 24px;
    height: 32px;
    border-radius: 50px;
}

.card .card_right .members .img img {
    width: 32px;
    height: 32px;
    border-radius: 50px;
}

.wrapper .box_right {
    padding: 24px;
    min-width: 376px;
    max-width: 376px;
    height: calc(100vh - 96px);
    overflow: auto;
    background: #ffffff;
    border-left: 1px solid #efefef;
}

.wrapper .box_right .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #292d32;
    margin-bottom: 12px;
}

/* box right calendar */

.wrapper .box_right .calendar {
    padding-bottom: 24px;
    border-bottom: 1px solid #efefef;
}

.box_right .calendar .calendar_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}

.box_right .calendar .calendar_head p {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #292d32;
}

.box_right .calendar .calendar_head .buttons_wrapper {
    display: flex;
    column-gap: 12px;
    align-items: center;
}

.box_right .calendar .calendar_head .buttons_wrapper button {
    width: 28px;
    height: 28px;

    border: 1px solid #f6f7fa;
    border-radius: 6px;
}

.box_right .calendar .dates {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.box_right .calendar .dates p {
    font-weight: 400;
    font-size: 12px;
    line-height: 160%;
    text-align: center;
    color: #a7aec1;
}

.box_right .calendar .dates p .date {
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    color: #292d32;
    display: block;
    margin-top: 6px;

    width: 31px;
    height: 31px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box_right .calendar .dates p .date.active {
    background: #03bdb3;
    color: #ffffff;
}

/* box right foods */

.box_right .foods_wrapper {
    margin-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #efefef;
}

.box_right .foods_wrapper .card {
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 8px;
    margin-bottom: 12px;
}

.foods_wrapper .card .card_left {
    display: flex;
    column-gap: 12px;
}

.foods_wrapper .card .card_left img {
    width: 57px;
    height: 57px;
    border-radius: 8px;
    object-fit: contain;
}

.foods_wrapper .card .card_left .texts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.foods_wrapper .card .card_left .texts p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #292d32;
    margin-bottom: 6px;
}

.foods_wrapper .card .card_left .texts span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #a7aec1;
}

.foods_wrapper .cards {
    display: flex;
    column-gap: 12px;
}

.foods_wrapper .cards .card_item {
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 8px;
}

.foods_wrapper .cards .card_item .card_top {
    display: flex;
    column-gap: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #292d32;
    margin-bottom: 8px;
}

.foods_wrapper .cards .card_item .card_top img {
    width: 38px;
    height: 38px;
    border-radius: 6px;
}

.foods_wrapper .cards .card_item span {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #a7aec1;
}

.box_right .brithday_wrapper {
    margin-top: 24px;
}

.box_right .brithday_wrapper .card {
    width: 100%;
    border: 1px solid #efefef;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 12px;
}

.box_right .brithday_wrapper .card:last-child {
    margin-bottom: 0;
}

.brithday_wrapper .card .card_left {
    display: flex;
    column-gap: 12px;
    align-items: center;
}

.brithday_wrapper .card .card_left img {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    object-fit: contain;
}

.brithday_wrapper .card .card_left .texts p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #292d32;
    margin-bottom: 4px;
}

.brithday_wrapper .card .card_left .texts span {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #a7aec1;
}

.brithday_wrapper .card .card_right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 39px;
}

.brithday_wrapper .card .card_right span {
    width: 10px;
    height: 10px;
    background: #03bdb3;
    border-radius: 100%;
}

.brithday_wrapper .card .card_right p {
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    color: #292d32;
}

.bar_chart{
    margin-top: 25px;
}