.wrapper {
  padding: 24px;
  height: calc(100vh - 96px);
  overflow: auto;
  background-color: #F5F5F5;
}

.wrapper .section_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.wrapper .section_head .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #292d32;
}

.wrapper .section_head .add_btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  width: 226px;
  height: 44px;
  background: #03bdb3;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

tbody tr .name {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

tbody tr .name img {
  border-radius: 8px;
  width: 32px;
  height: 32px;
}

.wrapper .body_items {
  margin: 30px 0 24px;
  border-bottom: 1px solid #efefef;
  height: calc(100vh - 207px);
}

.wrapper .body_items .add_img__wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.body_items .add_img__wrapper .image {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  background: rgba(3, 189, 179, 0.06);
  border: 1px dashed #03bdb3;
  border-radius: 10px;
}

.body_items .add_img__wrapper .texts p {
  color: #a6b1bb;
  margin-bottom: 8px;
}

.body_items .add_img__wrapper .texts label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #03bdb3;
  cursor: pointer;
}

.wrapper .item_wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;
}

.wrapper .bottom_btn {
  width: 100%;
  height: 49px;
  border-radius: 10px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.wrapper .bottom_btn.add {
  background: #03bdb3;
  color: #ffffff;
}

.wrapper .bottom_btn.cancel {
  background: #f6f7fa;
  color: #b6bfc8;
}

.tabel_wrapper {
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
}

.tabel_wrapper .table {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  border-collapse: collapse;
}

.tabel_wrapper .table thead tr {
  height: 44px;
  background: #f6f7fa;
}

.tabel_wrapper .table thead tr th {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8c9194;
  text-align: left;
  padding-left: 16px;
  border-right: 1px solid #efefef;
}

.tabel_wrapper .table thead tr th:first-child {
  border-radius: 8px 0px 0px 8px;
}

.tabel_wrapper .table thead tr th:last-child {
  border-right: none;
  border-radius: 0px 8px 8px 0px;
}

.tabel_wrapper .table tbody tr {
  /* height: 51px; */
  border-bottom: 1px solid #efefef;
}

.tabel_wrapper .table tbody tr td {
  padding: 16px 0 9px 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #a6b1bb;
}

.tabel_wrapper .table tbody tr td .more_settings {
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
}

.table tbody tr td .more_settings .more_card {
  position: absolute;
  top: -15px;
  left: -100px;
  width: 156px;
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: none;
  padding: 8px;
  z-index: 5;
}

.more_settings .more_card .more_item {
  padding: 6px 8px;
  border-radius: 6px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #a6b1bb;
}

.more_settings .more_card .more_item:hover {
  background: #f6f7fa;
  color: #292d32;
  cursor: pointer;
}

.more_settings .more_card .more_item:hover svg path {
  stroke: #292d32;
}

#more-btn:checked~.more_card {
  display: block;
}

.table_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}

.table_footer .footer_left {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.table_footer .footer_left p {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #a6b1bb;
}

.table_footer .footer_left .select {
  padding: 8px 12px;
  width: 68px;
  height: 40px;
  border: 1px solid #eeeff2;
  border-radius: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #292d32;
  background: url(../../assets/images/icons/small-arrow-down.svg) no-repeat 80%;
}

.table_footer .footer_right {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.table_footer .footer_right .pagination_conroller {
  border: 1px solid #f6f7fa;
  border-radius: 8px;
  width: 40px;
  height: 40px;
}

.table_footer .footer_right .pagination {
  display: flex;
  align-items: center;
}

.table_footer .footer_right .pagination .btn {
  width: 40px;
  height: 40px;
  border-radius: 8px;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.table_footer .footer_right .pagination .btn.active {
  font-weight: 700;
  background: #03bdb3;
  color: #ffffff;
}