.wrapper {
  padding: 24px;
  height: calc(100vh - 96px);
  overflow: auto;
  background-color: #F5F5F5;
}
.wrapper .section_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.wrapper .section_head .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #292d32;
}
.wrapper .section_head .add_btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  width: 177px;
  height: 44px;
  background: #03bdb3;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.wrapper .section_body {
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
}



/* Повышение and Новые сотрудники sections */

.cards_wrapper {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}
.cards_wrapper .card {
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 12px;
}
.cards_wrapper .card .card_head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #f6f7fa;
}
.cards_wrapper .card .card_head .head_left {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.cards_wrapper .card .card_head .head_left img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}
.cards_wrapper .card .card_head .head_left p {
  font-weight: 600;
  line-height: 150%;
  color: #292d32;
}
.cards_wrapper .card .card_head .head_left span {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #a6b1bb;
}

.cards_wrapper .card .card_head .more_settings {
  width: 36px;
  height: 36px;

  background: #f0fbfa;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cards_wrapper .card .card_head .more_settings .more_card {
  position: absolute;
  top: -2px;
  left: -120px;
  width: 156px;
  height: 92px;
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: none;
  padding: 8px;
  z-index: 5;
}

.cards_wrapper .card .card_body {
  margin-top: 16px;
}

.cards_wrapper .card .card_body .texts {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.cards_wrapper .card .card_body .texts:last-child {
  margin-bottom: 0;
}
.cards_wrapper .card .card_body .texts span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a6b1bb;
}
.cards_wrapper .card .card_body .texts p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #292d32;
}

/* Больничные листы and Отпуска */

.tabel_wrapper thead .select {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8c9194;  
  width: 100%;
  background-color: #f6f7fa;

  background-image: url(../../assets/images/icons/arrow-down.svg);
  background-repeat: no-repeat;
  background-position: 95%;
}
.tabel_wrapper tbody tr .name {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.tabel_wrapper tbody tr .name img {
  border-radius: 8px;
  width: 32px;
  height: 32px;
}

/* popups style */
.wrapper .body_items {
  margin: 30px 0 24px;
  border-bottom: 1px solid #efefef;
  height: calc(100vh - 202px);
}
.wrapper .body_items .add_img__wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.body_items .add_img__wrapper .image {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  background: rgba(3, 189, 179, 0.06);
  border: 1px dashed #03bdb3;
  border-radius: 10px;
}
.body_items .add_img__wrapper .texts p {
  color: #a6b1bb;
  margin-bottom: 8px;
}
.body_items .add_img__wrapper .texts label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #03bdb3;
  cursor: pointer;
}
.wrapper .item_wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;
}
.wrapper .bottom_btn {
  width: 100%;
  height: 49px;
  border-radius: 10px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.wrapper .bottom_btn.add {
  background: #03bdb3;
  color: #ffffff;
}
.wrapper .bottom_btn.cancel {
  background: #f6f7fa;
  color: #b6bfc8;
}

.popup_card {
  display: none;
}
.popup_card.active {
  display: block;
}
