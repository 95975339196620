.accordions {
    display: flex;
    column-gap: 16px;
}

.accordions .accordion {
    margin-bottom: 16px;
}

.accordions .accordion:last-child {
    margin-bottom: 0;
}

.accordion ul {
    padding: 16px 0;
}

.accordion ul li {
    margin-bottom: 12px;
    list-style-type: none;
}

.accordion ul li:last-child {
    margin-bottom: 0;
}

.accordion ul li a {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #03BDB3;
    text-decoration: none;
}

.accordion .accordion__slot_view button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.accordion .accordion__slot_view {
    border: 1px solid #efefef;
    border-radius: 10px;
    padding: 16px;
}
.accordion__slot_hidden{
    display: none;
}