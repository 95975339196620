.wrapper {
  padding: 24px;
  height: calc(100vh - 96px);
  overflow: auto;
  background-color: #f5f5f5;
}

.wrapper .section_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.wrapper .section_head .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #292d32;
}

.wrapper .section_head .add_btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;

  width: 226px;
  height: 44px;
  background: #03bdb3;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}

.wrapper .section_body {
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
}

.section_body .tab_buttons {
  display: flex;
  border-bottom: 1px solid #efefef;
}

.section_body .tab_buttons .button {
  padding: 0 12px;
  height: 33px;
  border-bottom: 1px solid transparent;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a6b1bb;
}

.section_body .tab_buttons .button.active {
  color: #03bdb3;
  border-bottom: 1px solid #03bdb3;
}

.tabel_wrapper .table tbody tr .name {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.tabel_wrapper .table tbody tr .name img {
  border-radius: 8px;
  width: 32px;
  height: 32px;
}

.status {
  border-radius: 6px;
  padding: 5px 14px;
  max-width: 120px;
  display: flex;
  align-items: center;
  column-gap: 8px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.status .status_item {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.status.active {
  background: #f0fbfa;
  color: #03bdb3;
  padding: 5px 14px;
  max-width: 120px;
}

.status.active .status_item {
  background: #03bdb3;
}

.status.rejected {
  background: rgba(243, 93, 72, 0.11);
  color: #f35d48;
}

.status.rejected .status_item {
  background: #f35d48;
}

.status.interview {
  background: rgba(255, 210, 50, 0.1);
  color: #ffd232;
}

.status.interview .status_item {
  background: #ffd232;
}

.popup-card {
  display: none;
}

.popup-card.active {
  display: block;
}

/* popups style */

.wrapper .body-items {
  margin: 30px 0 24px;
  border-bottom: 1px solid #efefef;
  height: calc(100vh - 202px);
  overflow: auto;
}

.wrapper .body-items .add_img__wrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.body-items .add_img__wrapper .image {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;
  background: rgba(3, 189, 179, 0.06);
  border: 1px dashed #03bdb3;
  border-radius: 10px;
}

.body-items .add_img__wrapper .texts p {
  color: #a6b1bb;
  margin-bottom: 8px;
}

.body-items .add_img__wrapper .texts label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #03bdb3;
  cursor: pointer;
}

.wrapper .item-wrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 100%;
}

.popup-card .card-body .card-img {
  width: 100%;
  height: 135px;
  border-radius: 8px;
}

.popup-card .card-body .card-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #efefef;
}

.popup-card .card-body .card-top .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 150%;
  color: #292d32;
}

.popup-card .card-body .item-text {
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid #efefef;
}

.popup-card .card-body .item-text p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #8c9194;
  margin-bottom: 16px;
}

.popup-card .card-body .item-text p span {
  font-weight: 600;
  color: #292d32;
  margin-left: 16px;
}

.popup-card .card-body .text-content {
  padding: 24px 0 16px;
}

.popup-card .card-body .text-content .title {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #292d32;
}

.popup-card .card-body .text-content .text {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8c9194;
  margin-top: 12px;
}

.wrapper .bottom-btn {
  width: 100%;
  height: 49px;
  border-radius: 10px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}

.wrapper .bottom-btn.add {
  background: #03bdb3;
  color: #ffffff;
}

.wrapper .bottom-btn.cancel {
  background: #f6f7fa;
  color: #b6bfc8;
}

/*pop-up styles*/

.add_btn{
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  background-color: #03BDB3;
  border-radius: 10px;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  outline: none;
}

.add_btn img{
  margin-right: 10px;
}

.modal_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #EFEFEF;
  padding-bottom: 12px;
  margin-bottom: 30px;
}
.modal_body{
  flex-grow: 1;
}

.modal_form{
  display: flex;
  flex-wrap: wrap;
}

.form_label{
  width: 48%;
  font-size: 16px;
  line-height: 19px;
  color: #8C9194;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.form_label_max{
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  color: #8C9194;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.form_input{
  border: none;
  width: 100%;
  height: 49px;
  background: #F6F7FA;
  border-radius: 10px;
  padding: 14px 10px;
  margin-top: 8px;
}

.form_label:nth-child(2n+1){
  margin-left: 4%;
}
.form_select{
  border: none;
  width: 100%;
  height: 49px;
  background: #F6F7FA;
  border-radius: 10px;
  padding: 14px 10px;
  margin-top: 8px;
}
.modal_footer{
  width: 100%;
  border-top: 1px solid #EFEFEF;
  padding-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.save_btn{
  cursor: pointer;
  border: none;
  padding: 15px 0;
  text-align: center;
  background: #03BDB3;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 48%;
}
.cancel_btn{
  cursor: pointer;
  border: none;
  padding: 15px 0;
  text-align: center;
  background: #F6F7FA;
  border-radius: 10px;
  color: #B6BFC8;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 48%;
}
.form_textarea{
  resize: none;
  margin-top: 8px;
  height: 150px;
  border-radius: 10px;
  padding: 4px;
  border: none;
  background-color: #F6F7FA;
}
.form_textarea:focus{
outline: none !important;
}