@font-face {
    font-family: SF PRO DISPLAY;
    src: local("SF Pro Display Bold"), local("SF-Pro-Display-Bld"), url("SF-Pro-Display-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: SF PRO DISPLAY;
    src: local("SF Pro Display Semibold"), local("SF-Pro-Display-Semibld"), url("SF-Pro-Display-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: SF PRO DISPLAY;
    src: local("SF Pro Display Medium"), local("SF-Pro-Display-Med"), url("SF-Pro-Display-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: SF PRO DISPLAY;
    src: local("SF Pro Display Regular"), local("SF-Pro-Display-Reg"), url("SF-Pro-Display-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: SF PRO DISPLAY;
    src: local("SF Pro Display Light"), local("SF-Pro-Display-Lgt"), url("SF-Pro-Display-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: SF PRO DISPLAY;
    src: local("SF Pro Display Thin"), local("SF-Pro-Display-Thn"), url("SF-Pro-Display-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
}
