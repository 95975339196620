.login{
    width: 50%;
    height: 100vh;
    padding: 50px 100px;
}
.login_top{
    margin-bottom: 150px;
}
.login_title{
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: rgb(41, 45, 50);
    margin-bottom: 36px;
}
.login_form_box{
    width: 450px;
}
.login_label input{
    width: 100%;
    height: 49px;
    margin-top: 8px;
    font-size: 16px;
    padding: 14px 10px;
    background: #F6F7FA;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-bottom: 24px;
}
.login_label span{
    font-size: 16px;
    line-height: 19px;
    color: #8C9194;
    margin-bottom: 8px;
}
.login_password{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* The container */
.checkbox_label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    user-select: none;
}

.checkbox_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 1px solid #03BDB3;
    border-radius: 5px;
}


.checkbox_label:hover input ~ .checkmark {
    background-color: #fff;
}

.checkbox_label input:checked ~ .checkmark {
    background-color: #fff;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox_label input:checked ~ .checkmark:after {
    display: block;
}

.checkbox_label .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 11px;
    border: solid #03BDB3;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.forgot_password{
    color: #03BDB3;
}
.login_submit{
    width: 100%;
    height: 49px;
    background: #03BDB3;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    border: none;
    margin-top: 20px;
}

