.timeline {
    position: relative;

}

.timeline table {
    width: 100%;
}

.calendar {
    display: flex;
    align-items: center;
    width: 100%;
}

.search {
    display: flex;
    max-width: 210px;
    height: 36px;
    margin: 8px 16px;
    padding: 8px 10px;
    border-radius: 8px;
    gap: 8px;
    background-color: #F6F7FA;
}

.search>input {
    background: none;
    width: 100%;
    font-size: 14px;
    color: #292D32;
}

.search>input::placeholder {
    color: #A6B1BB;
    font-size: 14px;
}

.month {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 16px 8px;
}

.month_pagination {
    display: flex;
    gap: 12px;
}

.next_prev {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    width: 36px;
    height: 36px;
}

.timeline_monipulation {
    display: flex;
    border-bottom: 1px solid #EFEFEF;
}

.part_title {
    display: flex;
    align-items: center;
    min-width: 230px;
    max-width: 300px;
    height: 100%;
    border-right: 1px solid #EFEFEF;
}

.part_title h4 {
    margin-left: 16px;
}

.timeline table {
    border-collapse: collapse;
    border-style: hidden;
    max-height: 263px;
    overflow-y: auto;
}

.timeline>table>thead th {
    border: 1px solid #EFEFEF;
    max-width: 300px;
    min-width: 27px;
}

.timeline>table>thead th:first-child {
    width: 230px;
}

.timeline>table>tbody td {

    border: 1px solid #EFEFEF;
}

.timeline>table>tbody td:first-child {
    width: 230px;
}

.timeline_duration {
    align-items: center;
    border-radius: 8px;
    padding: 2px;
    height: 36px;
    margin-left: auto;
    display: flex;
    background-color: #F6F7FA;
    margin-right: 16px;
}

.duration {
    display: flex;
    cursor: pointer;
    border-radius: 6px;
    justify-content: center;
    width: 79px;
    padding: 8px 0;
    align-items: center;
    color: #A6B1BB;
    font-size: 14px;
    font-weight: 400;
}

.duration_line {
    top: 0;
    left: 0;
    position: absolute;
    height: 36px;
    width: 150px;
    border-radius: 8px;
    background-color: #FFD232;
}

.line {
    position: absolute;
    top: 130px;
    left: 232px;
}

.employee {
    margin: 10px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.employee img {
    width: 32px;
    height: auto;
}

.employee p {
    color: #A6B1BB;
    font-size: 14px;
    font-weight: 400;
}

.selected_month {
    text-transform: capitalize;
}

.active {
    border-radius: 6;
    font-weight: 600;
    color: #292D32;
    background-color: #fff;
}

.table_month {
    color: #A6B1BB;
    font-weight: 400;
}

.table_month > span:first-child {
    font-weight: 500;
    color: #292D32;
}
.table_week{
    text-transform: capitalize;
    min-width: 120px;
    font-size: 12px;
    font-weight: 600;
    width: 135px;
}
