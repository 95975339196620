.password{
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 21px 24px;
}

.password_top{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #292D32;
    padding-bottom: 8px;
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 24px;
}

.password_body{
    display: flex;
    flex-direction: row;
    padding-bottom: 32px;
    border-bottom: 1px solid #EFEFEF;
}
.password_label{
    flex-grow: 1;
    color: #8C9194;
}

.password_input_box{
    display: flex;
    width: 95%;
    background-color: #F6F7FA;
    border-radius: 10px;
    justify-content: space-between;
    padding: 14px 10px;
    font-size: 14px;
    line-height: 150%;
    margin-top: 8px;
}

.password_input_box input{
    width: 100%;
}

.options_bottom{
    padding-top: 24px;
    display: flex;
    justify-content: end;
}

.edit_form{
    cursor: pointer;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    background: #03BDB3;
    border-radius: 10px;
    padding: 15px 66px;
    color: #fff;
}

.password_title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}