.add_btn{
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    background-color: #03BDB3;
    border-radius: 10px;
    padding: 12px 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    outline: none;
}

.add_btn img{
    margin-right: 10px;
}

.modal_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 12px;
    margin-bottom: 30px;
}
.modal_body{
    flex-grow: 1;
}

.modal_form{
    display: flex;
    flex-wrap: wrap;
}

.form_label{
    width: 48%;
    font-size: 16px;
    line-height: 19px;
    color: #8C9194;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.form_label_max{
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    color: #8C9194;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.form_input{
    border: none;
    width: 100%;
    height: 49px;
    background: #F6F7FA;
    border-radius: 10px;
    padding: 14px 10px;
    margin-top: 8px;
}

.form_label:nth-child(2n){
    margin-left: 4%;
}
.form_select{
    border: none;
    width: 100%;
    height: 49px;
    background: #F6F7FA;
    border-radius: 10px;
    padding: 14px 10px;
    margin-top: 8px;
}
.modal_footer{
    width: 100%;
    border-top: 1px solid #EFEFEF;
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.save_btn{
    cursor: pointer;
    border: none;
    padding: 15px 0;
    text-align: center;
    background: #03BDB3;
    border-radius: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: 48%;
}
.cancel_btn{
    cursor: pointer;
    border: none;
    padding: 15px 0;
    text-align: center;
    background: #F6F7FA;
    border-radius: 10px;
    color: #B6BFC8;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: 48%;
}
