.body{
    display: flex;
    align-items: center;
    min-width: 120px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
}
.decline{
    background-color: #F35D481C;
    color: #F35D48;
}
.review{
    background-color: #FFD2321A;
    color: #FFD232;
}
.aprove{
    background-color: #F0FBFA;
    color: #03BDB3;
}