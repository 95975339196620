.table_content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    justify-content: center;
    box-sizing: border-box;
}

.day_number{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
}

.event{
    width: 100%;
    padding: 4px 10px;
    background: #F6F7FA;
    border-radius: 12px;
}
.event_name{
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #292D32;
}

.event_name:before{
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #03BDB3;
    margin-right: 8px;
}
