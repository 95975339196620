.more{
    position: relative;
}
.more_card{
    right: 36px;
    top: 260px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 10px;
    position: absolute;
    width: 156px;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.05);
    justify-content: center;
}
.more_item{
    padding-left: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    gap: 12px;
    height: 36px;
}
.more_item svg path{
    stroke: #A6B1BB;
}
.more_item:hover{
    background-color: #F6F7FA;
    color: #292D32;
    stroke: #292D32;
}