.calendar {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 30px 24px;
    background-color: #f6f7fa;
}

.calendar_header {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
}

.header_inner{
    display: flex;
    align-items: center;
}

.calendar_title{
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #292D32;
    margin-right: 10px;
}

.btns_box{
    display: flex;
    align-items: center;
}

.calendar_btns{
    padding: 12px;
    mix-blend-mode: normal;
    opacity: 0.6;
    border: 1px solid #EFEFEF;
    border-radius: 8px;
    margin-right: 10px;
}
.calendar_body {
    width: 100%;
    height: 800px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.table_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    border-width: 0px 1px 1px 0px;
    border-style: solid;
    border-color: #EFEFEF;
    border-radius: 10px 0px 0px 0px;
}

.weekday {
    width: 100px;
    display: flex;
    text-align: center;
    justify-content: center;
    height: 40px;
}

.weekday_text{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
}

