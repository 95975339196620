* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.swiper {
  padding-bottom: 30px !important;
}

body {
  font-family: "SF PRO DISPLAY", sans-serif;
  font-weight: 400;
}

input {
  outline: none;
  border: none;
  background-color: #f6f7fa;
}

button {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

select {
  outline: none;
  border: none;
  cursor: pointer;
  appearance: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 0;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(136, 136, 136, 0.5);
}

.calendar-day {
  height: 134px;
  position: relative;
  border: 1px solid #EFEFEF;
  background-color: #fff;
  padding: 10px 20px;
}

.calendar-day:hover {
  background-color: rgba(0,0,0,.10);
}

.calendar-day p {
  text-align: end;
  color: #a6a6a6;
}

.current p {
  color: #000000;
}

.selected p {
  color: #cc0000;
  font-weight: bold;
}
.modal_content{
  position: absolute;
  top: 0;
  right: 0;
  width: 470px;
  height: 100vh;
  background-color: #fff;
  padding: 32px 24px;
  transition: ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
}

.modal_hiden{
  width: 0;
  overflow: hidden;
}

.modal{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(166, 177, 187, 0.2);
  backdrop-filter: blur(10px);
  z-index: 100;
}

.modal_hiden_bg{
  width: 0;
  overflow: hidden;
}

.openDrop{
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  padding: 8px;
  width: 240px;
  background: #ffffff;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-top: 6px;
}

.drop_info_items {
  display: none;
}

.wrapper_item{
  padding: 14.5px 18px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #A6B1BB;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.activeTab{
  padding: 14.5px 18px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  background: #F6F7FA;
  color: #292D32;
  display: flex;
  flex-direction: row;
  align-items: center;
}

