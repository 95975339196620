.wrapper {
    padding: 24px;
    background-color: #f5f5f5;
}

.wrapper .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #292d32;
    margin-bottom: 20px;
}

.wrapper .box {
    width: 100%;
    height: calc(100vh - 193px);
    background: #ffffff;
    border-radius: 10px;
}
.box .section_card{
    margin-top: 24px;
}
.box .section_card .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #a6b1bb;
}

.box .section_card .text b {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #292d32;
}

.box .section_card .cards_wrapper {
    margin: 24px 0;
}

.box .section_card .cards_wrapper .wrapper_title {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #292d32;
    margin-bottom: 16px;
}

.box .section_card .cards_wrapper .cards {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.section_card .cards_wrapper .cards .card {
    max-width: 253px;
    width: 100%;
    height: 156px;
    background: #f6f7fa;
    border-radius: 12px;
    padding: 16px;
}

.cards_wrapper .cards .card .card_head {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.cards_wrapper .cards .card .card_head .card_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #292d32;
}

.cards_wrapper .cards .card .card_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #a6b1bb;
    margin-top: 12px;
}

.section_card .accordions {
    display: flex;
    column-gap: 16px;
}

.accordions .accordion {
    margin-bottom: 16px;
}

.accordions .accordion:last-child {
    margin-bottom: 0;
}

.section_card .accordions .accordion__panel {
    padding: 0;
}

.section_card .accordions .accordion__panel .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #a6b1bb;
    padding: 16px;
}