.wrapper .sidebar {
    width: 288px;
    min-width: 288px;
    height: 100vh;
    background: #ffffff;
    border-right: 1px solid #efefef;
    padding: 24px 16px;
    overflow: auto;
  }
  .wrapper .sidebar img {
    margin: 0 0 16px 16px;
  }
  
  .sidebar .sidebar_menu .link {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding: 10px 16px;
    height: 48px;
    border-radius: 12px;
  
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #a6b1bb;
    margin-top: 16px;
  }
  .sidebar .sidebar_menu .link:hover {
    background: #f6f7fa;
  }
  .sidebar .sidebar_menu .link.active {
    color: #292d32;
    background: #f6f7fa;
  }
  .sidebar .sidebar_menu .link.active svg path {
    stroke: #292d32;
  }
  
  .wrapper .container {
    width: 100%;
  }
  