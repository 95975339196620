.wrapper{
    display: flex;
    height: calc(100vh - 96px);
}

.wrapper_left{
    display: flex;
    flex-direction: column;
    width: 282px;
    background: #FFFFFF;
    border-right: 1px solid #EFEFEF;
    height: calc(100vh - 96px);
    padding: 16px 12px;
}

.wrapper_right{
    width: calc(100vw - 570px);
    background-color: #F6F7FA;
    padding: 24px;
}

.options_img{
    margin-right: 10px;
}