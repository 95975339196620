.wrapper .navbar {
    width: 100%;
    height: 96px;
    background: #ffffff;
    border-bottom: 1px solid #efefef;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 57px;
  }
  .wrapper .navbar h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #292d32;
    width: 100%;
  }
  
  .input_search {
    display: flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
  }
  .input_search .input {
    padding: 10px 14px 10px 38px;
    width: 352px;
    height: 49px;
    background: #f6f7fa;
    border-radius: 10px;
    font-size: 14px;
    line-height: 150%;
    color: #292d32;
  }
  .input_search .input::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #a6b1bb;
  }
  .input_search .input:focus {
    background: #ffffff;
    border: 1px solid #03bdb3;
  }
  .input_search .icon_search {
    position: absolute;
    left: 10px;
  }
  .input_search .search_card {
    display: none;
    width: 352px;
    max-height: 247px;
    overflow: auto;
    background: #ffffff;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: 16px;
    position: absolute;
    top: 55px;
    left: 0;
  }
  
  .input_search .card_item .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #292d32;
  }
  .input_search .card_item .subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #a6b1bb;
    margin-top: 6px;
  }
  .input_search .card_item .label {
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 12px;
    line-height: 160%;
    color: #a6b1bb;
  }
  
  .input_search .card_item .item {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .input_search .card_item .item .text {
    font-size: 14px;
    line-height: 18px;
    color: #292d32;
  }
  
  .input-search .card_item .iamge_wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .input_search .card_item .iamge_wrapper img {
    border-radius: 8px;
    width: 42px;
    height: 42px;
  }
  
  .navbar .user_info {
    position: relative;
  }
  .navbar .user_info .info {
    padding: 4px 8px 4px 4px;
    width: 240px;
    height: 48px;
    background: #f6f7fa;
    border-radius: 10px;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.2s ease-in-out;
  }
  .navbar .user_info .info.active {
    background: #ffffff;
    border: 1px solid #03bdb3;
    transition: 0.2s ease-in-out;
  }
  .navbar .user_info .info.active .icon_arrow {
    transform: rotate(180deg);
    transition: 0.2s ease-in-out;
  }
  .navbar .user_info .info_left {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .navbar .user_info .info_left .user_img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
  }
  .navbar .user_info .info_left .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #292d32;
  }
  .navbar .user_info .info_left .job {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #8c9194;
    margin-top: 4px;
  }

  .drop_item {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 8px;
    border-radius: 6px;
    transition: 0.2s;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #a6b1bb;
  }
  
  .drop_item:hover {
    background: #f6f7fa;
    color: #292d32;
  }
  .drop_item:hover svg path {
    stroke: #292d32;
  }