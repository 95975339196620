.section_body {
  display: block;
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
}

.section_body .tab_buttons {
  display: flex;
  border-bottom: 1px solid #efefef;
}

.section_body .tab_buttons .button {
  padding: 0 12px;
  height: 33px;
  border-bottom: 1px solid transparent;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a6b1bb;
}

.section_body .tab_buttons .button.active {
  color: #03bdb3;
  border-bottom: 1px solid #03bdb3;
}