.options{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 12px 24px;
    overflow: auto;
}

.options_top{
    padding: 8px 0;
    border-bottom: 1px solid #EFEFEF;
}

.options_top_title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #292D32;
}

.options_info{
    display: flex;
    flex-direction: row;
    padding: 18px 0;
}

.options_img{
    margin-right: 12px;
    border-radius: 8px;
    width: 80px;
    height: 80px;
    overflow: hidden;
}

.options_img img{
    width: 100%;
    height: 100%;
}

.info_text{
    font-size: 16px;
    line-height: 140%;
    color: #A6B1BB;
    margin-bottom: 16px;
}

.info_bottom{
    display: flex;
    flex-direction: row;
}

.photo_upload{
    cursor: pointer;
    padding: 10px 16px;
    border: none;
    background-color: #03BDB3;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    margin-right: 24px;
}

.edit_btn{
    border: none;
    padding: 10px 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #03BDB3;
}

.options_form{
    padding: 6px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-bottom: 1px solid #EFEFEF;
}

.options_label{
    width: 50%;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 19px;
    color: #8C9194;
    margin-bottom: 20px;
}

.options_input{
    width: 90%;
    background-color: #F6F7FA;
    border-radius: 10px;
    padding: 14px 10px;
    font-size: 14px;
    line-height: 150%;
    margin-top: 8px;
}

.options_bottom{
    padding-top: 24px;
    display: flex;
    justify-content: end;
}

.edit_form{
    cursor: pointer;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    background: #03BDB3;
    border-radius: 10px;
    padding: 15px 66px;
    color: #fff;
}