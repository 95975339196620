.link{
    width: 100%;
    padding: 21px 24px;
    background-color: #fff;
    height: 100%;
    border-radius: 10px;
}

.link_top{
    padding-bottom: 8px;
    border-bottom: 1px solid #EFEFEF;
    margin-bottom: 16px;
}

.link_body{
    border-bottom: 1px solid #EFEFEF;
}

.link_title{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #292D32;
}

.link_subtitle{
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #292D32;
    margin-bottom: 12px;
}

.link_body_part{
    margin-bottom: 24px;
}

.link_inner{
    background-color: #F6F7FA;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: row;
}

.link_label{
    display: flex;
    flex-direction: column;
    color: #8C9194;
    flex-grow: 1;
}

.link_input{
    width: 95%;
    border-radius: 10px;
    background-color: #fff;
    font-size: 14px;
    line-height: 150%;
    color: #292D32;
    padding: 14px 10px;
    margin-top: 8px;
}

.link_input2{
    width: 98%;
    border-radius: 10px;
    background-color: #fff;
    font-size: 14px;
    line-height: 150%;
    color: #292D32;
    padding: 14px 10px;
    margin-top: 8px;
}

.options_bottom{
    padding-top: 24px;
    display: flex;
    justify-content: end;
}

.edit_form{
    cursor: pointer;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    background: #03BDB3;
    border-radius: 10px;
    padding: 15px 66px;
    color: #fff;
}