.wrapper{
    width: 50%;
    height: 100vh;
    background-color: rgb(3, 189, 179);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.body_title{
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;
    color: #fff;
    margin-bottom: 12px;
 }
.slider_body{
    margin-top: 32px        ;
}

.body_text{
    font-size: 18px;
    line-height: 150%;
    color: #fff;
    text-align: center;
}

.slider_container {
    width: 535px;
}
.slider_imgbox{
    display: flex;
    align-items: center;
    justify-content: center;
}
